<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        v-if="!contentLoading"
        ref="refUserListTable"
        class="position-relative overflow_table_cstm"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :sort-by.sync="sortBy"
      >
        <template #cell(airline)="data">
          {{ data.item.name }}
        </template>

        <template #cell(code)="data">
          {{ data.item.code }}
        </template>

        <template #cell(baggage_info)="data">
          <div style="width: 100%;">
            <validation-observer ref="baggageInfoFormRef" v-slot="{invalid}">
            <b-form @submit.prevent>
              <div style="display: flex;">
                <validation-provider
                  style="width: 100%"
                  #default="{ errors }"
                  name="baggage info"
                  rules="required|min:2|max:1000"
                >
                  <!-- <b-form-input
                    v-model="data.item.baggageInfo"
                    placeholder="Baggage Info"
                  /> -->
                  <b-form-group>
                    <quill-editor
                        id="baggageInfo"
                        v-model="data.item.baggageInfo"
                        class="baggageInfo text-wrap"
                        :options="snowOption"
                        :preserve-whitespace="false"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Baggage Info"
                      />
                    
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <feather-icon
                  style="cursor: pointer; margin: 0.8rem;"
                  size="22"
                  icon="SaveIcon"
                  :disabled="invalid"
                  @click="invalid ? false :addEditBaggageInfo(data.item.baggageInfo, data.item.id)"
                />
              </div>
            </b-form>
          </validation-observer>
          </div>
        </template>

      </b-table>
      <Loader v-if="contentLoading" />
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ totalAirlines >= 1 ? startIndex + 1 : startIndex }} to
              {{ endIndex > totalAirlines ? totalAirlines : endIndex }} of
              {{ totalAirlines }} entries</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalAirlines"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup
} from "bootstrap-vue";
import vSelect from "vue-select";
import {
  resConditionCheck,
  perPage,
  currentPage,
  perPageOptions,
  RoleEnum,
} from "@/helpers/constant";
import airlineBaggageService from "@/services/airline-baggage/airline-baggage.service";
import errorResponseHandler from "@/services/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Loader from "@/layouts/skeloton-loader/Loader.vue";
import checkLoginRole from "@/helpers/checkLoginRole";
import accessRightCheck from "@/helpers/accessRightCheck";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
} from "@/@core/utils/validations/validations";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BCard,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BRow,
    BCol,
    BFormInput,
    BForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    Loader,
    quillEditor
  },

  data() {
    return {
      contentLoading: true,
      items: [],
      startIndex: 0,
      endIndex: 0,
      tableColumns: [
        {
          key: "name",
          label: "Airline",
          sortable: true
        },
        { key: "code" },
        { key: "baggage_info" }
      ],
      currentPage,
      perPage,
      perPageOptions,
      searchQuery: "",
      sortBy: "",
      totalAirlines: 0,
      isSortDirDesc: false,
      checkLoginRole,
      RoleEnum,
      checkAccess: {},
      required,
      snowOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "link"]
          ]
        }
      },
    };
  },

  watch: {
    currentPage: {
      handler(val) {
        this.listAirlines();
        this.$router.push({name: 'airline-baggage-list', query: {page:val, rows:this.perPage}}).catch(()=>{});
      }
    },
    perPage: {
      handler(val) {
        // this.currentPage = 1;
        this.listAirlines();
        this.$router.push({name: 'airline-baggage-list', query: {page:this.currentPage, rows:val}}).catch(()=>{});
      }
    },
    sortBy: {
      handler(newValue) {
        this.sortBy = newValue;
        this.listAirlines();
      }
    },
    isSortDirDesc: {
      handler() {
        this.listAirlines();
      }
    },

    searchQuery: {
      handler() {
        this.currentPage = 1;
        if (this.searchQuery.length >=2 || this.searchQuery.length == 0) {
          this.listAirlines();
        }
      }
    }
  },

  async mounted() {
    await this.listAirlines();
    if (this.$route.query) {
      this.currentPage = this.$route.query.page ? this.$route.query.page : 1;
      this.perPage = this.$route.query.rows ? this.$route.query.rows : 10;
    }
    this.checkAccess = accessRightCheck(this.$route.meta.module.name);
  },

  methods: {
    // storeResortName(name, id) {
    //   localStorage.setItem("resort_name", name);
    //   this.$router.push({ name: "local-tax-edit", params: { id }, query: {page: this.currentPage, rows: this.perPage} });
    // },

    async listAirlines() {
      this.contentLoading = true
      const payload = {
        no_of_result: this.perPage,
        page: this.currentPage,
        order: this.isSortDirDesc === true ? "DESC" : "ASC",
        order_by: this.sortBy,
        search: this.searchQuery
      };

      try {
        const res = await airlineBaggageService.listAirlines(payload);
        if (resConditionCheck(res.status) && res.data.airport_list) {
          this.contentLoading = false;
          this.items = res.data.airport_list;
          this.totalAirlines = res.data.count;
          this.startIndex = this.currentPage * this.perPage - this.perPage;
          this.endIndex = this.startIndex + this.perPage;
        }
      } catch (error) {
        const errorData = errorResponseHandler(error);
        this.contentLoading = false;
        if (error.response.status === 404) {
          this.items = [];
          this.totalAirlines = 0;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger"
            }
          });
        }
      }
    },

    async addEditBaggageInfo(payload, id) {
      try { 
        console.log("_-----------", payload, id)
        let data = {
          info: payload
        }
        // this.contentLoading = true
        const res = await airlineBaggageService.addEditAirlineBaggage(data, id);
  
        if (resConditionCheck(res.status) && res.data.data) { 
          // this.contentLoading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res?.data?.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
        }
      } catch (error) {
          const errorData = errorResponseHandler(error);
          // this.contentLoading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger"
            }
          });
        }
      }
    }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
