import axios from 'axios'

function listAirlines(payload) {
  return axios.get('flight/list-airlines', { params: payload })
}

function addEditAirlineBaggage(data, id) {
  return axios.post(`flight/baggage-info/${id}`, data)
}

export default {
  listAirlines,
  addEditAirlineBaggage
}
